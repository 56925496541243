import { useCallback } from "react";

import { optimizelyClient } from "./OptimizelyClient";

const useOptimizelyEvents = () => {
  const loadEvents = useCallback(() => {
    const projectConfig = optimizelyClient?.getOptimizelyConfig();
    const events = projectConfig?.events.map((e) => e.key);
    return events;
  }, []);

  return loadEvents;
};

export default useOptimizelyEvents;
