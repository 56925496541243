import { createInstance } from "@optimizely/react-sdk";

import { applicationSettings } from "@/constants";

const { OPTIMIZELY_SDK_KEY } = applicationSettings;

export const optimizelyClient = createInstance({
  sdkKey: OPTIMIZELY_SDK_KEY,
  eventBatchSize: 10,
  eventFlushInterval: 1000
});
