"use client";

import { applicationSettings } from "@/constants";
import { logger } from "@/logger";

const adjustEvents = {
  "account-registration-cta-sign-up": "cw5iq5",
  "account-registration-failed": "xpplwh",
  "account-registration-onboarding-experience-selected": "1vieda",
  "account-registration-onboarding-interests-selected": "6xgn06",
  "account-registration-successful": "dqjna3",
  "account-subscription-final-cancel-cta-clicked": "uif6ia",
  "account-subscription-winback-cta-clicked": "45nheg",
  "account-subscription-winback-offer-selected": "3ifdx6",
  "app-cta-download-android-app": "fz0ryf",
  "app-cta-download-ios-app": "s68oy7",
  "onboarding-focus-submitted": "v4ckvw",
  "onboarding-intro": "xhkdv2",
  "onboarding-number-of-classes-submitted": "rbh2fi",
  "onboarding-styles-submitted": "2kjo5f"
};

let Adjust: {
  initSdk: (arg0: { appToken: string; environment: "production" | "sandbox" }) => void;
  trackEvent: (arg0: { eventToken: string }) => void;
};
if (typeof window !== "undefined") {
  Adjust = require("@adjustcom/adjust-web-sdk");
}

export function initAdjustSDK() {
  try {
    if (!applicationSettings.ADJUST_APP_TOKEN || !applicationSettings.ADJUST_ENVIRONMENT) {
      logger.error("Missing required Adjust configuration");
    }
    Adjust.initSdk({
      appToken: applicationSettings.ADJUST_APP_TOKEN,
      environment: applicationSettings.ADJUST_ENVIRONMENT
    });
  } catch (error) {
    logger.error("Failed to initialize Adjust SDK:", error);
  }
}

export function trackAdjustEvent(event: string) {
  const adjustEventName = event.replace(/\s-\s|\s/g, "-").toLowerCase() as keyof typeof adjustEvents;
  const eventToken = adjustEvents[adjustEventName];

  if (!eventToken || !Adjust.trackEvent) return;

  Adjust.trackEvent({ eventToken });
}
