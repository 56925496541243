import { useMemo, useCallback } from "react";
import { NextRequest } from "next/server";

import { ANALYTICS_EVENT } from "@/features/Analytics/types";
import { logger } from "@/logger";
import { defaultOptions, getStorage } from "@/lib/storage";

import useVersion from "../useVersion";
import { useTrackEvent } from "./useTrackEvent";

type TrackRegisterCtaProps = {
  pageName?: string;
};

type UseAnalyticsProps = {
  defaultPageName?: string;
};

type AdParams = {
  fbclid: string | null;
  gclid: string | null;
  msclkid: string | null;
};

export const getAdParams = (days: number = defaultOptions.defaultExpirationDays!, req?: NextRequest): AdParams => {
  return {
    fbclid: getStorage("fbclid", days, req),
    gclid: getStorage("gclid", days, req),
    msclkid: getStorage("msclkid", days, req)
  };
};

export const useAnalytics = ({ defaultPageName }: UseAnalyticsProps = {}) => {
  const trackEvent = useTrackEvent();
  const web_build_version = useVersion();
  const platform = "Web";

  const OS = useMemo(() => {
    if (typeof navigator === "undefined") {
      return "Unknown";
    }

    const userAgent = (navigator?.userAgent ?? "").toLowerCase();

    if (userAgent.includes("win")) return "Windows";
    if (userAgent.includes("mac")) return "macOS";
    if (userAgent.includes("linux")) return "Linux";
    if (userAgent.includes("android")) return "Android";
    if (userAgent.includes("ios")) return "iOS";

    return "Unknown";
  }, []);

  const getCommonProperties = useCallback(() => {
    return {
      ...getAdParams(),
      platform,
      web_build_version,
      OS,
      timestamp: new Date().toISOString()
    };
  }, [OS, web_build_version]);

  const trackRegisterCta = useCallback(
    (properties: TrackRegisterCtaProps = {}) => {
      try {
        const commonProperties = getCommonProperties();
        const { pageName } = properties;
        trackEvent(ANALYTICS_EVENT.ACCOUNT_REGISTRATION_CTA_SIGN_UP, {
          ...commonProperties,
          pageName: pageName ?? defaultPageName
        });
      } catch (error) {
        logger.error("Failed to track register CTA:", error);
      }
    },
    [defaultPageName, getCommonProperties, trackEvent]
  );

  return { getCommonProperties, trackRegisterCta };
};
