import { type NextResponse, NextRequest } from "next/server";

import { applicationSettings } from "@/constants";

const { isProduction } = applicationSettings;

interface StorageOptions {
  defaultExpirationDays?: number;
  req?: NextRequest;
  res?: NextResponse;
}

interface LocalStorageData {
  value: string;
  timestamp: number;
}

export const defaultOptions: StorageOptions = {
  defaultExpirationDays: 30
};

const isBrowser = (): boolean => typeof window !== "undefined" && typeof document !== "undefined";

export const setCookie = (
  name: string,
  value: string,
  days: number = defaultOptions.defaultExpirationDays!,
  res?: NextResponse
): void => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  const cookieString = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/`;

  if (isBrowser()) {
    document.cookie = cookieString;
  } else if (res) {
    try {
      res.cookies.set({
        name,
        value: encodeURIComponent(value),
        expires,
        httpOnly: true,
        path: "/",
        sameSite: "lax",
        secure: isProduction
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const getCookie = (name: string, req?: NextRequest): string | null => {
  if (isBrowser()) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return decodeURIComponent(parts.pop()!.split(";").shift()!);
    return null;
  } else if (req) {
    const cookieValue = req.cookies.get(name)?.value;
    return cookieValue ? decodeURIComponent(cookieValue) : null;
  }
  return null;
};

export const setLocalStorage = (name: string, value: string): void => {
  if (!isBrowser() || !localStorage) return;
  const data: LocalStorageData = { value, timestamp: Date.now() };
  localStorage.setItem(name, JSON.stringify(data));
};

export const getLocalStorage = (name: string, days: number = defaultOptions.defaultExpirationDays!): string | null => {
  if (!isBrowser() || !localStorage) return null;
  const data = localStorage.getItem(name);
  if (!data) return null;

  const parsedData: LocalStorageData = JSON.parse(data);
  const expirationMs = days * 24 * 60 * 60 * 1000;
  return Date.now() - parsedData.timestamp <= expirationMs ? parsedData.value : null;
};

export const setStorage = (
  name: string,
  value: string,
  days: number = defaultOptions.defaultExpirationDays!,
  res?: NextResponse
): void => {
  setCookie(name, value, days, res);
  setLocalStorage(name, value);
};

export const getStorage = (
  name: string,
  days: number = defaultOptions.defaultExpirationDays!,
  req?: NextRequest
): string | null => {
  const cookieValue = getCookie(name, req);
  if (cookieValue !== null) return cookieValue;
  return isBrowser() ? getLocalStorage(name, days) : null;
};

export const removeStorage = (name: string, res?: NextResponse): void => {
  if (isBrowser()) {
    setCookie(name, "", -1);
    localStorage?.removeItem(name);
  } else if (res) {
    res.cookies.delete(name);
  }
};

export const clearStorage = (): void => {
  if (!isBrowser() || !localStorage) return;

  document.cookie.split(";").forEach((cookie) => {
    const [name] = cookie.split("=");
    setCookie(name.trim(), "", -1);
  });
  localStorage.clear();
};

export const createStorage = (options: StorageOptions = {}) => {
  const days = options.defaultExpirationDays ?? defaultOptions.defaultExpirationDays!;
  const { req, res } = options;

  return {
    setCookie: (name: string, value: string, customDays?: number) => setCookie(name, value, customDays ?? days, res),
    getCookie: (name: string) => getCookie(name, req),
    setLocalStorage: (name: string, value: string) => setLocalStorage(name, value),
    getLocalStorage: (name: string, customDays?: number) => getLocalStorage(name, customDays ?? days),
    setStorage: (name: string, value: string, customDays?: number) => setStorage(name, value, customDays ?? days, res),
    getStorage: (name: string, customDays?: number) => getStorage(name, customDays ?? days, req),
    removeStorage: (name: string) => removeStorage(name, res),
    clearStorage
  };
};
