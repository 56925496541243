import { useCallback } from "react";

import useOptimizelyEvents from "@/hooks/analytics/useOptimizelyEvents";
import useVersion from "@/hooks/useVersion";
import { logger } from "@/logger";
import { trackAdjustEvent } from "@/core/services/adjust";

import { analytics } from "./SegmentAnalytics";

export const useTrackEvent = () => {
  const optimizelyEvents = useOptimizelyEvents();
  const version = useVersion();

  const trackEvent = useCallback(
    (event: string, properties: object, callback?: () => void) => {
      const isOptimizelyEvent = (event: string) => optimizelyEvents()?.includes(event);

      const eventPropertes = {
        client: "web",
        web_build_version: version,
        ...properties
      };

      const integrationOptions = {
        integrations: {
          Iterable: false,
          Optimizely: isOptimizelyEvent(event)
        }
      };

      try {
        const trackAnalyticsEvent = (event: string, properties: object, options: object) => {
          if (analytics) {
            analytics.track(event, properties, options, callback);
          }
        };
        trackAnalyticsEvent(event, eventPropertes, integrationOptions);
        trackAdjustEvent(event);
      } catch (error) {
        logger.error("Error tracking event", error);
      }
    },
    [optimizelyEvents, version]
  );

  return trackEvent;
};
